import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import trikes from "../../ducks/trikes";
import { bindActionCreators } from "redux";
import moment from 'moment';
import Select from 'react-select-nested-group';
import { Spinner } from '../common/Spinner/index';
import { TripMap } from '../common/maps/tripMap';
import Modal from 'react-bootstrap4-modal';
import Stepper from 'react-stepper-horizontal';
import DateTime from 'react-datetime';
import toastr from "toastr";
import Table from 'react-bootstrap/Table'
import { Popover, PopoverBody } from 'reactstrap';
import { DateRangePicker } from 'react-date-range';
import calenderIcon from '../../assets/img/calender.png';
import { subDays } from 'date-fns';

import "./index.css"

const {
  InfoWindow,
  Polygon,
  Circle
} = require("react-google-maps");

class TrikesPlayback extends Component {

  constructor() {
    super();
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    this.state = {
      mapHeight: "40vh",
      selectedDate: null,
      selectedVehicle: null,
      selectedHours: null,
      isLoadingRecords: true,
      loadingPlateNumber: true,
      loading: false,
      vehicleNumberPlate: "",
      thingId: "",
      path: [],
      ignitionOn: [],
      ignitionOff: [],
      loadMap: false,
      geofence: [],
      geofencesArea: [],
      routeFences: [],
      tourInfo: [],
      distance: null,
      harshAcceleration: [],
      voilations: {},
      enableHarshAcceleration: true,
      enableHarshBrakes: true,
      enableHarshTurn: true,
      enableOverSpeeding: true,
      isSearchCriteriaVisible: true,
      stops: [],
      position: {},
      playbackSpeed: { value: 1, label: '1x' },
      playbackStatus: null,
      companyId: companyInfo ? companyInfo.companyId : "",
      infoWindowContent: {
        angle: 0,
        latlng: {
          lat: 0.0,
          lng: 0.0
        },
        address: "",
        speed: 0,
        distance: 0
      }
    }
    this.gridElement = React.createRef();
    this.tbody = React.createRef();
  }

  async componentDidMount() {

    await this
      .props
      .trikesAction
      .getVehiclesPlateNumber();
    if (this.props.vehiclesPlateNumber.length) {
      await this.setState({
        ...this.state,
        thingId: this.props.vehiclesPlateNumber[0]._id,
        isLoadingRecords: true,
        loadingPlateNumber: false,
      });
      this.handlePlateNumberChange({ value: this.props.vehiclesPlateNumber[0]._id })
    } else {
      this.setState({
        ...this.state,
        loadingPlateNumber: false,
        isLoadingRecords: false,
        loading: false
      });
    }


    if (this.state.companyId === "672c88ae37c7c36cba2b2fd2"){
      this.setState({ ...this.state, mapHeight: "75vh" });
    }
  }


  componentWillUnmount() {
    this.props.trikesAction.clearVehicleDetails();
    this.setState({ ...this.state, playbackStatus: null })
  }

  animate = () => {
    if (this.state.playbackStatus === 'playing') {
      const { currentIndex } = this.state;
      this.setState({ ...this.state, mapHeight: "80vh" });

      const nextIndex = currentIndex + 1;
      const { length } = this.state.completePath;
      if (length === nextIndex) {
        return this.setState({ ...this.state, playbackStatus: 'stop' });
      }
      const position = this.state.path[nextIndex];
      const startDist = this.state.completePath[0].OdoMeter;
      const { angle, latlng, address, speed, event, OdoMeter, time } = this.state.completePath[nextIndex];

      this.setState(prevState => {
        if (prevState.infoWindowContent.latlng && prevState.infoWindowContent.latlng.lat === latlng.lat && prevState.infoWindowContent.latlng.lng === latlng.lng) {
          return null;
        }
        return {
          infoWindowContent: {
            angle,
            latlng,
            address,
            speed,
            distance: (OdoMeter - startDist) / 1000,
            time
          }
        };
      });

      if (nextIndex % 5 === 0) {
        this.gridElement.current.scrollTo({ top: this.tbody.current.clientHeight / this.state.path.length * (nextIndex + 1), behavior: 'smooth' });
      }

      setTimeout(() => {
        this.setState({
          ...this.state,
          currentIndex: nextIndex,
          position,
          angle
        });
        setTimeout(() => {
          this.animate();
        }, Math.round(600 / this.state.playbackSpeed.value));
      }, 10);

    } else if (this.state.playbackStatus === 'stop') {
      this.setState({ ...this.state, mapHeight: "40vh" });
    } else {
      this.setState({ ...this.state, mapHeight: "40vh" });
    }
  }

  handlePlateNumberChange = async (selectedOption) => {
    const thingId = selectedOption ? selectedOption.value : null;
    if (thingId) {
      await this.setState({
        ...this.state,
        isLoadingRecords: true,
        loading: true,
        mapLoading: false,
        vehicleNumberPlate: selectedOption
          ? selectedOption.value
          : "",
        thingId
      });
      this.props.trikesAction.clearVehicleDetails();

      await this
        .props
        .trikesAction
        .GetVehicleDetails({ id: thingId });
      this.setState({ ...this.state, loading: false });
    }

  }
  searchNewCriteria = async ({ selectedDate, endDate, selectedVehicle }) => {
    await this.setState({
      ...this.state,
      selectedDate,
      endDate,
      selectedVehicle,
      isSearchCriteriaVisible: true,
      completePath: []
    });

    await this.handlePlateNumberChange(selectedVehicle);

    await this.fetchTrackPlayback({ selectedDate, endDate, selectedVehicle });
  }
  changeCriteria = async () => {
    this.setState({ ...this.state, isSearchCriteriaVisible: true });
  }

  fetchTrackPlayback = async ({ selectedDate, endDate, selectedVehicle }) => {

    const start = selectedDate;
    const end = endDate;
    // const difference = moment(start).diff(moment(end), 'minutes');

    const interval = { "startDate": start.format(), "endDate": end.format() };

    this.setState({ ...this.state, mapLoading: true });

    var data = null;

    data = await this.props.trikesAction.getTrackPoints(JSON.stringify({ interval }), selectedVehicle.value) || {};

    let { path: _path = [] } = data;

    if (!_path.length) {
      toastr.error("No record found, Select another criteria")
      await this.setState({ ...this.state, selectedVehicle: null, isLoadingRecords: false, loading: false, mapLoading: false });
      this.changeCriteria();
    } else {
      // _path = _path
      // .map((point, index, _path) => {
      //     if(point.type==='event'){
      //        const _point =  R.findLast(_point=>_point.type==='observation'&&R.equals(_point.latlng,point.latlng))(_path);
      //      console.log({_point});
      //        return _point?{...point,time:_point.time}:point;
      //     }
      //     return point;
      // })
      // .sort((a,b)=>moment(a.time,'MM/DD/YYYY HH:mm:SS').diff(moment(b.time,'MM/DD/YYYY HH:mm:SS')))
      const path = _path.map(point => point.latlng);
      // console.log({data});
      // debugger;
      const {
        geofence = {},
        geofencesArea = [],
        routeFences = [],
        tourInfo = [],
        distance = 0,
        counts: voilations = {},
        geoFenceCounts = {},
        fatigueList: _fatigueList = [],

        eventsListing: {
          HARSH_ACCELERATION: _harshAcceleration = [],
          HARSH_TURN: _harshTurn = [],
          SPEED_LIMIT: _overspeeding = [],
          HARSH_BRAKES: _harshBrakes = [],
          IGNITION_OFF: _ignitionOff = [],
          IGNITION_ON: _ignitionOn = [],

        } = {},
        eventsListing: voilationListTypes = [],
        duration = 0,
        idleTime = 0,
        idle = [],
        averageSpeed = 0,
        fuelConsumed = 0,
        stops = [],
      } = data;

      const mapCoordinates = acc => {
        if (acc.latlng && typeof acc.latlng === 'string') {
          const [lat, lng] = acc.latlng.split(',');
          return { ...acc, latlngCords: { lat: parseFloat(lat), lng: parseFloat(lng) } };
        }
        return acc; // Return unchanged if latlng is invalid
      };
      const harshAcceleration = _harshAcceleration.map(mapCoordinates);
      const harshTurn = _harshTurn.map(mapCoordinates);
      const ignitionOff = _ignitionOff.map(mapCoordinates);
      const ignitionOn = _ignitionOn.map(mapCoordinates);
      const overspeeding = _overspeeding.map(mapCoordinates);
      const harshBrakes = _harshBrakes.map(mapCoordinates);
      const fatigueList = _fatigueList.map(mapCoordinates);
      const cellSiteCoordinates = point => {
        if (point.eventType === 'ENTERING PATROLLING CELLSITE') {
          const { lat, lng } = point.latlng;
          return { ...point, latlngCords: { lat: parseFloat(lat), lng: parseFloat(lng) } }
        }
        return;
      }
      const cellSitePoints = _path.filter(point => point.eventType === 'ENTERING PATROLLING CELLSITE').map(cellSiteCoordinates);
      // const idle = _idle.map(mapCoordinates);

      this.setState({
        ...this.state,
        path,
        completePath: _path,
        mapLoading: false,
        loadMap: true,
        geofences: geofence,
        geofencesArea,
        routeFences,
        tourInfo,
        distance,
        harshAcceleration,
        harshTurn,
        ignitionOff,
        ignitionOn,
        overspeeding,
        harshBrakes,
        voilations,
        voilationListTypes,
        geoFenceCounts,
        duration,
        idleTime,
        idle,
        averageSpeed,
        fuelConsumed,
        stops,
        fatigueList,
        currentIndex: 0,
        position: path[0],
        angle: 180,
        enterCellSite: cellSitePoints
      });
    }
  }


  changeViolationData(data) {

    let voilationListTypes = Object.assign({}, this.state.voilationListTypes);
    if (data.type === "harshAcceleration") {


      voilationListTypes.HARSH_ACCELERATION = this.state.enableHarshAcceleration ? [] : this.state.voilationListTypes.HARSH_ACCELERATION

      this.setState({
        ...this.state,
        voilationListTypes,
        enableHarshAcceleration: !this.state.enableHarshAcceleration
      })

    }
    else if (data.type === "harshBrakes") {



      voilationListTypes.HARSH_BRAKES = this.state.enableHarshBrakes ? [] : this.state.voilationListTypes.HARSH_BRAKES

      this.setState({
        ...this.state,
        voilationListTypes,
        enableHarshBrakes: !this.state.enableHarshBrakes
      })
    }
    else if (data.type === "harshTurn") {

      voilationListTypes.HARSH_TURN = this.state.enableHarshTurn ? [] : this.state.voilationListTypes.HARSH_TURN

      this.setState({
        ...this.state,
        voilationListTypes,
        enableHarshTurn: !this.state.enableHarshTurn
      })

    }
    else if (data.type === "overSpeeding") {

      voilationListTypes.SPEED_LIMIT = this.state.enableOverSpeeding ? [] : this.state.voilationListTypes.SPEED_LIMIT

      this.setState({
        ...this.state,
        voilationListTypes,
        enableOverSpeeding: !this.state.enableOverSpeeding
      })
    }

  }
  changePlaybackSpeed = (playbackSpeed) => {
    this.setState({ ...this.state, playbackSpeed });
  }
  playbackRowSelected = (index, event) => {
    const currentIndex = index;
    const position = this.state.path[currentIndex];
    const { angle } = this.state.completePath[currentIndex];
    const infoWindowContent = `Lat: ${event.latlng ? event.latlng.lat : ''}, Lng: ${event.latlng ? event.latlng.lng : ''}, Address: ${event.address || ''}, Speed: ${event.speed}, Event: ${event.eventType}`;
    this.setState({ ...this.state, currentIndex: currentIndex, position, angle });

  }
  playPause = () => {
    const { playbackStatus } = this.state;
    if (!playbackStatus) {
      this.setState({ ...this.state, playbackStatus: 'playing' });
    }
    if (playbackStatus === 'playing') {
      this.setState({ ...this.state, playbackStatus: 'stop' });
    } else {
      this.setState({ ...this.state, playbackStatus: 'playing' });
    }
    setTimeout(() => { this.animate() }, 100);

  }
  resetPlayback = () => {

    this.gridElement.current.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ ...this.state, playbackStatus: null, currentIndex: 0 });

  }
  render() {

    let loader = <Spinner key="1" />
    const showContent = this.props.vehicleDetail && this.props.vehicleDetail.plateNumber;

    return (

      <div>


        {
          this.state.isSearchCriteriaVisible
          && !this.state.loadingPlateNumber && <ChangeModalWithOutStepper isVisible={this.state.isSearchCriteriaVisible}
            onCancel={() => {

              if (this.state.selectedVehicle) {
                this.setState({ ...this.state, isSearchCriteriaVisible: true });
              } else {
                this.props.history.push('/')
              }

            }}
            title={'Search Criteria'}
            onConfirm={this.searchNewCriteria}
            vehicles={this.props.vehiclesPlateNumber}
            companyId={this.state.companyId}
          />
        }

        <div>
          {showContent ? <div className="vehicle-plate-Number-model" >
            <p className="vehicle-plate-number">{this.props.vehicleDetail.plateNumber || "N/A"}</p>
            <p className="vehicle-model">{this.props.vehicleDetail.vehicleMakeAndModel || 'N/A'}</p>
            <p className="vehicle-model" style={{ fontSize: '16px' }} >Start Date: {this.state.selectedDate ? this.state.selectedDate.format('DD-MM-YYYY HH:mm') : 'Please Select a start date'}</p>
            <p className="vehicle-model" style={{ fontSize: '16px' }} >End Date: {this.state.endDate ? this.state.endDate.format('DD-MM-YYYY HH:mm') : 'Please Select a end date'}</p>
            <button
              className="btn auxo-primary-btn"
              style={{ margin: '0px' }}
              onClick={this.changeCriteria}
            >Change Criteria</button>
          </div> : ""
          }
          {this.state.mapLoading ? [loader] : <div>
            {this.state.loadMap ? <div>
              <div className="row">
                <div className="col-md-12">
                  <div style={{ position: "absolute", top: "0", left: "0" }}>
                    {this.state.infoWindowContent.speed > 0 && this.state.playbackStatus === "playing" && (
                      <InfoWindow position={this.state.infoWindowContent.latlng} >
                        <div className="custom-info-window">
                          <p>Lat: {this.state.infoWindowContent.latlng.lat}</p>
                          <p>Lng: {this.state.infoWindowContent.latlng.lng}</p>
                          <p>Address: {this.state.infoWindowContent.address}</p>
                          <p>Speed: {this.state.infoWindowContent.speed}</p>
                          <p>Distance: {this.state.infoWindowContent.distance} KM</p>
                        </div>
                      </InfoWindow>
                    )}
                  </div>


                  <div className='my-3'>
                    <TripMap
                      height={this.state.mapHeight}
                      playbackStatus={this.state.playbackStatus}
                      position={this.state.position}
                      angle={this.state.angle}
                      path={this.state.path}
                      distance={this.state.distance}
                      geofencesArea={this.state.geofencesArea}
                      routeFences={this.state.routeFences}
                      tourInfo={this.state.tourInfo}
                      harshAcceleration={this.state.enableHarshAcceleration ? this.state.harshAcceleration : []}
                      overSpeeding={this.state.enableOverSpeeding ? this.state.overspeeding : []}
                      harshTurn={this.state.enableHarshTurn ? this.state.harshTurn : []}
                      harshBrakes={this.state.enableHarshBrakes ? this.state.harshBrakes : []}
                      enterCellSite={this.state.enterCellSite || []}
                      ignitionOn={this.state.ignitionOn}
                      ignitionOff={this.state.ignitionOff}
                      stops={this.state.stops}
                      idle={this.state.idle}
                      company={this.state.companyId}
                      companyPath={this.state.completePath}
                      infoWindowContent={this.state.infoWindowContent}
                    />
                  </div>
                </div>
              </div>
              <div className="row" >
                <div className="col-md-3">
                  <label >Speed</label>
                  <Select
                    name="form-field-name"

                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Speed"
                    defaultValue={this.state.playbackSpeed}
                    onChange={this.changePlaybackSpeed}
                    options={
                      this.state.companyId === "664f4f93e440b860d2c1c93c"
                        ? [
                          { value: 1, label: '1x' },
                          { value: 2, label: '2x' },
                          { value: 4, label: '4x' },
                        ]
                        : [
                          { value: 1, label: '1x' },
                          { value: 2, label: '2x' },
                          { value: 4, label: '4x' },
                          { value: 6, label: '6x' },
                        ]
                    }
                  />

                </div>
                <div className="col-md-2">
                  <button
                    className="btn auxo-primary-btn"
                    style={{ marginTop: '25px', width: '150px' }}
                    onClick={this.playPause}
                  >{this.state.playbackStatus === 'playing' ? 'Pause' : 'Play'}</button>

                </div>
                <div className="col-md-2">
                  <button
                    className="btn auxo-primary-btn"
                    style={{ marginTop: '25px', width: '150px' }}
                    onClick={this.resetPlayback}
                  >Reset</button>

                </div>
              </div>


              <div className="row" style={{ marginTop: '5px', height: '40vh', overflowY: 'scroll', }} ref={this.gridElement}>
                <div className="col-md-12" >
                  <Table bordered >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date Time</th>
                        <th>Location</th>
                        <th>Speed</th>
                        <th>Angle</th>
                        <th>Status</th>
                        <th>Event</th>
                      </tr>
                    </thead>
                    <tbody ref={this.tbody}>
                      {this.state.completePath && this.state.completePath.map((event, index) => {
                        return (<tr style={{ background: index === this.state.currentIndex ? '#00abce69' : 'none' }} onClick={(event) => this.playbackRowSelected(index, event)} key={index} >
                          <td style={{ height: '42px' }}><span style={{ height: '42px' }}>{index + 1}</span></td>
                          <td style={{ height: '42px' }}>{moment(event.time, 'MM/DD/YYYY HH:mm:SS').format('DD-MM-YYYY HH:mm:SS')}</td>
                          <td style={{ height: '42px' }}>{this.state.companyId !== '5f9fe73eb44c0b1a122a38fd' ? event.latlng ? `${event.latlng.lat},${event.latlng.lng}` : '' : event.address}</td>
                          <td style={{ height: '42px' }}>{event.speed}</td>
                          <td style={{ height: '42px' }}>{event.angle}</td>
                          <td style={{ height: '42px' }}>{event.status}</td>
                          <td style={{ height: '42px' }}>{event.eventType}</td>
                        </tr>);
                      })}

                    </tbody>
                  </Table>
                </div>

              </div>
            </div> : <div></div>}


          </div>}

        </div>

      </div>


    );
  }
}


const ChangeModalWithOutStepper = (props) => {
  const getDefaultVehicle = () => {
    const storedVehicle = localStorage.getItem("selectedVehicle");
    if (storedVehicle) {
      return JSON.parse(storedVehicle);
    } else if (props.vehicles && props.vehicles.length > 0) {
      return {
        label: props.vehicles[0].plateNumber,
        value: props.vehicles[0]._id,
      };
    } else {
      return null;
    }
  };

  const [selectedVehicle, setSelectedVehicle] = useState(getDefaultVehicle);
  const [selectedDate, setSelectedDate] = useState(() => {
    const now = new Date();
    return new Date(now.setHours(0, 0, 0, 0));
  });
  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    return new Date(now.setHours(23, 59, 59, 999));
  });

  const [dateRangePicker, setDateRange] = React.useState({
    selection: {
      startDate: subDays(new Date(), 1),
      endDate: new Date(),
      key: 'selection',
    },
  },)

  const [popoverOpen, setPopoverOpen] = React.useState(false)

  useEffect(() => {
    if (selectedVehicle) {
      localStorage.setItem("selectedVehicle", JSON.stringify(selectedVehicle));
    }
  }, [selectedVehicle]);

  const handleRangeChange = async (payload) => {
    // setSelectedDate(payload.selection.startDate)
    // setEndDate(payload.selection.endDate)
    setDateRange({ ...payload })
  }

  const toggle = () => {
    setPopoverOpen(!popoverOpen)
  };

  const handleConfirm = () => {
    const hasValidSelectedDate = dateRangePicker && moment(dateRangePicker.selection.startDate).isValid();
    const hasValidEndDate = dateRangePicker && moment(dateRangePicker.selection.endDate).isValid();

    if (!hasValidSelectedDate) {
      return toastr.error("Start date is missing.");
    } else if (!hasValidEndDate) {
      return toastr.error("End date is missing.");
    }

    const startDateTime = moment(dateRangePicker.selection.startDate);
    const endDateTime = moment(dateRangePicker.selection.endDate);

    if (endDateTime.isBefore(startDateTime)) {
      return toastr.error("Start date should be less than the end date.");
    }

    const difference = moment(dateRangePicker.selection.endDate).diff(moment(dateRangePicker.selection.startDate), "minutes");

    if (difference > 1 * 24 * 60) {
      return toastr.error(
        "The selected time range exceeds the allowed limit."
      );
    }

    props.onConfirm({
      selectedVehicle,
      selectedDate: moment(dateRangePicker.selection.startDate),
      endDate: moment(dateRangePicker.selection.endDate),
    });
  };

  // const handleConfirm = () => {
  //   const { companyId } = props;
  //   const hasValidSelectedDate = selectedDate && moment(selectedDate).isValid();
  //   const hasValidEndDate = endDate && moment(endDate).isValid();

  //   if (!hasValidSelectedDate) {
  //     return toastr.error("Start date is missing.");
  //   } else if (!hasValidEndDate) {
  //     return toastr.error("End date is missing.");
  //   }

  //   const startDateTime = moment(selectedDate);
  //   const endDateTime = moment(endDate);

  //   if (endDateTime.isBefore(startDateTime)) {
  //     return toastr.error("Start date should be less than the end date.");
  //   }

  //   const difference = moment(endDate).diff(moment(selectedDate), "minutes");

  //   if (difference > 15 * 24 * 60) {
  //     return toastr.error(
  //       "The selected time range exceeds the allowed limit."
  //     );
  //   }

  //   props.onConfirm({
  //     selectedVehicle,
  //     selectedDate: moment(selectedDate),
  //     endDate: moment(endDate),
  //   });
  // };

  return (
    <>

      <div className="row justify-content-between w-100">

        <div className="col-md-3">
          <Select
            name="form-field-name"
            isSearchable={true}
            placeholder="Search Vehicle"
            onChange={setSelectedVehicle}
            value={selectedVehicle}
            options={
              props.vehicles &&
              props.vehicles.map((item) => ({
                value: item._id,
                label: item.plateNumber,
              }))
            }
          />
        </div>
        <div className="col-xs-6 col-sm-12 col-md-7 col-lg-6 col-xl-6 d-flex justify-content-end">
          <div className="float-right">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <a onClick={toggle} id="Popover1">
                  <img className="rag-picker" src={calenderIcon} alt="" />
                </a>
                <Popover placement="bottom" isOpen={popoverOpen} toggle={toggle} target="Popover1">
                  <PopoverBody>
                    <div>
                      <DateRangePicker
                        onChange={handleRangeChange}
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        className={'PreviewArea'}
                        months={1}
                        ranges={[dateRangePicker.selection]}
                        direction="horizontal"
                        maxDate={new Date()}
                      />
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-6 d-flex justify-content-center">
          <button onClick={handleConfirm} className='btn rag-primary-btn rag-score-filter-btn mx-2'>Confirm
          </button>
        </div>
      </div>

      {/* OLDDDDDDD */}

      {/* 
    <div className="change-stepper-container">
      <div className="left-panel">
        <div>
          <label>Select Vehicle</label>
          <Select
            name="form-field-name"
            isClearable={false}
            isSearchable={true}
            placeholder="Select Vehicle"
            defaultValue={selectedVehicle}
            onChange={setSelectedVehicle}
            options={
              props.vehicles &&
              props.vehicles.map((item) => ({
                value: item._id,
                label: item.plateNumber,
              }))
            }
          />
        </div>
        <div>
          <label>Start Date</label>
          <DateTime
            value={selectedDate}
            dateFormat="DD-MM-YYYY"
            timeFormat="hh:mm A"
            onChange={setSelectedDate}
          />
        </div>
        <div>
          <label>End Date</label>
          <DateTime
            value={endDate}
            dateFormat="DD-MM-YYYY"
            timeFormat="hh:mm A"
            onChange={setEndDate}
          />
        </div>
      </div>
      <div className="right-panel">
        <button
          onClick={handleConfirm}
          className="btn btn-contained"
          style={{ outline: "none" }}
        >
          Confirm
        </button>
      </div>
    </div> */}
    </>

  );
};

TrikesPlayback.propTypes = {};
TrikesPlayback.defaultProps = {};
const mapStateToProps = state => ({
  vehiclesPlateNumber: state.trikes.vehiclesPlateNumber,
  vehicleDetail: state.things.vehicleDetail,
});

// const mapDispatchToProps = dispatch => ({
//   actions: bindActionCreators(Things.creators, dispatch)
// });

const mapDispatchToProps = (dispatch) => ({
  trikesAction: bindActionCreators(trikes.creators, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TrikesPlayback));
