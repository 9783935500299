import { apiV1, apiV2, URL, MVDR_URL } from "../constants";

const urlV1 = URL + apiV1;
const urlV2 = URL + apiV2;

let urLReport = URL + apiV1;
// let urLReport = `http://localhost:8082/api/v1`;

let urLReportV2 = URL + apiV2;
//let urLReportV2 = `http://localhost:8082/api/v2`;

const domainEndPoints = {
  domain: "/domain",
};
const permissionEndPoints = {
  permission: "/permissions",
  userPermission: "/permissions/user",
  getPermissionsAndCompanies: "/permissions/companies",
  companyFeatures: "/companies",
};

const rolesEndPoints = {
  role: "/roles",
  individualRole: (id) => {
    return `/roles/${id}`;
  },
  companyRoles: (id) => {
    return `/roles/company/${id}`;
  },
};
  const ragAnalytics = {
    cardinalViolations: "/ragscore/cardinalViolations",
    getGroups: "/ragscore/getGroups",
    getThings: "/ragscore/getThings",
    ragscore: "/ragscore/analytics"
  }

  const trikesSummary = {
    getGroups: "/trikes/getGroups",
    getThings: "/trikes/getThings",
    getSummary: "/trikes/summary",
    getTrackPoints: (id) => {
      return `/trikes/${id}/track`;
    },
  }



  const telcoSiteEndpoints = {
    getThings: "/andromeda-telco/getThings",
    getTelcoSiteTour: () => {
      return `/andromeda-telco/getTelcoSiteTour`;
    },
    getTelcoSites: (groupId) => {
      return `/andromeda-telco/group/${groupId}`;
    },
    createSite: (groupId) => {
      return `/andromeda-telco/group/${groupId}`;
    },
    getVehiclePath: (id) => {
      return `/andromeda-telco/${id}/getVehiclePath`;
    },
    bulkUpload: (groupId) => {
      return `/andromeda-telco/bulk/${groupId}`;
    },
  }

const authEndpoints = {
  login: "/auth/login",
  forgotPassword: "/auth/forgot-password",
  confirmCode: "/auth/set-password",
  confirmUser: "/auth/confirm-user",
  refreshToken: "/auth/refresh-token",
  logout: "/auth/logout",
};

const companyEndPoints = {
  geofence: (company) => {
    return `${urlV1}/companies/${company}/geofences`;
  },
  routefence: (company) => {
    return `${urlV1}/companies/${company}/routefences`;
  },
};

const userEndpoints = {
  user: "/users",
};

const fuelEndPoint = {
  fuelGroupBaseUrl: (id) => `${urlV1}/companies/${id}/fuelGroup`,
  updateFuelGroup: (fuelGroupId) =>
    `${urlV1}/companies/fuelGroup/${fuelGroupId}`,
  fuelGroupSlot: (slotId) => `${urlV1}/companies/fuel/slots/${slotId}`,
};
const thingsEndPoints = {
  unAssignedThings: (id) => {
    return `/things/un-assigned-vehicles/${id}`;
  },
  getGroupVehicle: (id) => {
    return `/things/group/${id}`;
  },
  assignedThings: (id) => {
    return `/things/assign-vehicles/${id}`;
  },
  singleVehicles: (id) => {
    return `/things/single-vehicles`;
  },
  mapVehicles: (id) => {
    return `/things/map-vehicles/${id}`;
  },
  mapShareVehicles: (sharedId) => {
    return `/dev/mapShare-vehicles/${sharedId}`;
  },
  getCompanyRouterObservationsURL : (id ) => {
    return `router/company/${id}`;
  },
  getIndividualRouterObservationsUrl : (id,data ) => {
    return `/router/${id}?data=${data}`;
  },
  getCompanyTemperatureObservationsURL : (id ) => {
    return `things/temperature/company/${id}`;
  },
  getDeletedThings : (id ) => {
    return `things/get-delete-things/${id}`;
  },
  getIndividualTemepratureObservationsUrl : (id, data ) => {
    return `/things/temperature/${id}?data=${data}`;
  },
  getSummaryWidgets: (id, thingId) => {
    const filterUrl = thingId ? `?thingId=${thingId}` : "";
    return `/things/summary-widgets/${id}${filterUrl}`;
  },
  getHelmetSummaryWidgets: (id, thingId) => {
    const filterUrl = thingId ? `?thingId=${thingId}` : '';
    return `/things/helmet/summary-widgets/${id}${filterUrl}`;
  },
  activeInactiveStats: (id) => {
    return `/things/activeInactiveStats/${id}`;
  },
  groupPlateNumber: (id) => {
    return `/things/assignedPlateNumbers/${id}`;
  },
  exportGroupVehicles: (id,status) => {
    return `${urlV1}/things/groups/${id}/${status}/vehicles/export`;
  },
  getVehicleTrips: (id, skip, interval, sort) => {
    interval = JSON.stringify(interval);
    return `/things/${id}/trips?skip=${skip}&interval=${interval}&sort=${sort}`;
  },
  makeTripPrivate: (id) => {
    return `/things/maketripsprivate/${id}`;
  },
  getTripDetails: (id) => {
    return `/things/trips/${id}`;
  },
  getVehicleDetails: (id) => {
    return `/things/${id}`;
  },
  killEngine: (id) => {
    return `/things/${id}/engine-kill`;
  },
  killEngineCode: (id) => {
    return `/things/${id}/engine-kill/code`;
  },
  abortEngineKill: (id) => {
    return `/things/${id}/engine-kill/abort`;
  },
  releaseEngine: (id) => {
    return `/things/${id}/engine-release`;
  },
  abortEngineRelease: (id) => {
    return `/things/${id}/engine-release/abort`;
  },
  getTrackPoints: (id) => {
    return `/things/${id}/track`;
  },
  getTrackPointsBigWay: (id) => {
    return `/things/${id}/track-bigway`;
  },
  getTrackPointsBigWayChunk: (id) => {
    return `/things/${id}/track-bigway-chunks`;
  },
  getGeoFenceTrackPoints: (id) => {
    return `/things/${id}/geofenceTrack`;
  },
  observations: (thingId, data) => {
    return `/things/${thingId}/observations?data=${data}`;
  },
  violation: () => `${urlV1}/things/violation/stats`,
  tripsChart: () => `${urlV1}/things/tripsChart/stats`,
  gdChart: (id, data) => `${urlV1}/things/gdChart/stats/${id}?data=${data}`,
  fuelChart: () => `${urlV1}/things/fuel/stats`,
  fuelChartNew: () => `${urlV1}/things/fuel/statsNew`,
  updatePrivateMode: (thingId) => `${urlV1}/things/${thingId}/private-mode`,
  getVehicleScore: (id) => {
    return `/things/${id}/scorecard`;
  },
  fetchAddress: () => {
    return `/things/fetchAddress`;
  },
  createSharedUrl: () => {
    return `/things/createSharedUrl`;
  },
  setPassengerSeatBeltCheck: ({thingId,isPassenger}) => {
    return `/things/passengercheck/${thingId}/${isPassenger}`;
  },
};

const groupEndPoints = {
  groupUsers: (id) => {
    return `/groups/${id}/users`;
  },
  subGroups: (id) => {
    return `/groups/${id}/subgroups`;
  },
  individualGroup: (id) => {
    return `/groups/${id}`;
  },
  updateGroup: (id) => {
    return `/groups/${id}/update `;
  },
  updateFatigueThreshold: (id) => {
    return `/groups/${id}/fatigue-threshold `;
  },
  groups: () => {
    return `/groups`;
  },
  companyGroups: (id) => {
    return `/groups/company/${id}`;
  },
  domainPath: (id) => {
    return `/groups/${id}/domain-path`;
  },
  groupsAndVehicles: (id) => {
    return `/groups/${id}/vehicles/groups`;
  },
  groupsAndDrivers: (id) => {
    return `/groups/${id}/drivers/groups`;
  },
  drivingScoreSetting: (id) => {
    return `/companies/${id}/drivingScore/settings`;
  },
  drivingHoursConfig: (id) => {
    return `/companies/${id}/driverHours/settings`;
  },
};

const driverEndPoints = {
  groupDrivers: (id) => {
    return `/drivers/groups/${id}`;
  },
  individualDriver: (id) => {
    return `/drivers/${id}`;
  },
  importDrivers: (id) => {
    return `/drivers/import/groups/${id}`;
  },
};

const complaintsEndPoints = {
  individualComplaint: (id) => {
    return `/complaints/${id}`;
  },
  getComplaints: (groupId) => {
    return `/complaints/all/${groupId}`;
  },
  getCommentsForComplaint: (groupId,complaintId) => {
    return `/complaints/all/${groupId}/getCommentsForComplaint/${complaintId}`;
  },
  updateClosedToReopn: (groupId,complaintId) => {
    return `/complaints/all/${groupId}/updateClosedToReopn/${complaintId}`;
  },
};

const geoFenceEndPoints = {
  geoFenceList: (groupId) => {
    return `/geofence/list/all/group/${groupId}`;
  },
  WithoutPaging: (groupId) => {
    return `/geofence/list/allWithoutPaging/group/${groupId}`;
  },
  geoFence: (groupId) => {
    return `/geofence/group/${groupId}`;
  },
  individualGeoFence: (id, groupId) => {
    return `/geofence/${id}/group/${groupId}`;
  },
  deleteGeoFence: (groupId) => {
    return `/geofence/group/${groupId}/TobeDeleted`;
  },
  importGeofence: (groupId) => {
    return `/geofence/bulk/group/${groupId}`;
  },
};
const hotspotEndPoints = {
  hotspotList: (groupId) => {
    return `/hotspot/list/all/group/${groupId}`;
  },
  hotspot: (groupId) => {
    return `/hotspot/group/${groupId}`;
  },
  individualHotspot: (id, groupId) => {
    return `/hotspot/${id}/group/${groupId}`;
  },
  importHotspot: (groupId) => {
    return `/hotspot/bulk/group/${groupId}`;
  },
};
const pjpEndPoints = {
  pjpList: (groupId) => {
    return `/pjp/list/all/group/${groupId}`;
  },
  pjp: (groupId) => {
    return `/pjp/group/${groupId}`;
  },
  individualPJP: (id) => {
    return `/pjp/${id}`;
  },
  stats: (groupId) => {
    return `/pjp/stats/group/${groupId}`;
  },
  generalStats: (groupId) => {
    return `/pjp/stats/general/group/${groupId}`;
  },
  violationListing: (groupId, qs) => {
    return `/pjp/violation/list/group/${groupId}?type=${qs}`;
  },
};
const PatrollingEndPoints = {
  cellSites: (companyId) => {
    return `/patrolling/cellsites/list/${companyId}`;
  },
  bulkCellSites: () => {
    return `/patrolling/cellsites/bulkCreate`;
  },
  vehicleDriver: (id) => {
    return `/drivers/vehicle/${id}`;
  },
  individualCellSite: (id) => {
    return `/patrolling/cellsites/${id}`;
  },
  listCellSitesAndVehicles: (id) => {
    return `/patrolling/${id}/vehicles_cellsites`;
  },
  listCellSitesAndVehiclesList: (id) => {
    return `/patrolling/${id}/vehicles_cellsites/list`;
  },
  createCellSite: () => {
    return `/patrolling/cellsites`;
  },
  patrollingRegions: (companyId) => {
    return `/patrolling/regions/company/${companyId}`;
  },
  patrollingRegion: (id) => {
    return `/patrolling/regions/${id}`;
  },
  addPatrollingRegion: (id) => {
    return `/patrolling/regions`;
  },
  rescueCellSite: () => {
    return `/patrolling/rescueCellSite`;
  },
  getInProgressrescueCellSite: (companyId) => {
    return `/patrolling/company/${companyId}/rescueCellSite/inprogress`;
  },
  cancelrescueCellSite: (rescueCellSiteId) => {
    return `/patrolling/rescueCellSite/${rescueCellSiteId}/cancel`;
  },
  completeRescueCellSite: (rescueCellSiteId) => {
    return `/patrolling/rescueCellSite/${rescueCellSiteId}/complete`;
  },
  roster: () => {
    return `/roster`;
  },
  rostersList: (companyId) => {
    return `/roster/company/${companyId}`;
  },
  rosterWithId: (id) => {
    return `/roster/${id}`;
  },
};
const routeFenceEndPoints = {
  routeFence: (groupId) => {
    return `/routeFence`;
  },
  groupRouteFence: (groupId) => {
    return `/routeFence/group/${groupId}`;
  },
  individualRouteFence: (id) => {
    return `/routeFence/${id}`;
  },
};
const scheduleEndPoints = {
  slots: (id) => {
    return `/schedule/slots/${id}`;
  },
  assignSlot: (companyId) => {
    return `/schedule/slots/assign/${companyId}`;
  },
  schedule: (companyId) => {
    return `/schedule/${companyId}`;
  },
  scheduleDelete: (companyId, scheduleId) => {
    return `/schedule/${companyId}/${scheduleId}`;
  },
  scheduleAndDrivers: (companyId, vehicleId) => {
    return `/schedule/${companyId}/drivers/${vehicleId}`;
  },
};

const reportsEndPoints = {
  tripReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (`${urLReport}/reports/trips?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`);
  },
  tripReportCummulative: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (`${urLReport}/reports/tripCummulative?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`);
  },

  TripMilageReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (`${urLReport}/reports/tripsMilageReport?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`);
  },


  activityReport : (thingId, data) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (`${urLReport}/reports/activityReport?token=` + localStorage.refreshToken +`&companyId=${companyInfo.companyId}&data=${data}&thingId=${thingId}`);
  },
  geofenceTrackReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/geoFence-track?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  fuelAverageReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/fuelAvg?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  fuelVisualReport: (obj) => {
    obj = JSON.stringify(obj);
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (`${urLReportV2}/reports/fuel-visual?data=${obj}&token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`);
  },
  fuelVisualReportNew: (obj) => {
    obj = JSON.stringify(obj);
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (`${urLReportV2}/reports/fuelVisualNew?data=${obj}&token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`);
  },
  violationReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return `${urLReportV2}/reports/violations?token=${localStorage.refreshToken}&companyId=${companyInfo.companyId}`;
  },
  violationsGraph: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return `${urLReportV2}/reports/violationsGraph?token=${localStorage.refreshToken}&companyId=${companyInfo.companyId}`;
  },
  violationVisualReport: (obj) => {
    obj = JSON.stringify(obj);
    return `${urLReportV2}/reports/violations-visual?data=${obj}&token=${localStorage.refreshToken}`;
  },
  violationReportCustom: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return `${urLReportV2}/reports/violations-custom?token=${localStorage.refreshToken}&companyId=${companyInfo.companyId}`;
  },

  geofenceReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/geoFence?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  geofenceSummaryReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/geofenceSummaryReport?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  geofenceSummaryReportView: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/geofenceSummaryReportView?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  mileageReportView: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/mileageReportView?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  mileageReportExport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/mileageReportExport?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  newfuelAverage: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/fuelReportSummaryExport?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  fuelReportView: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/fuelReportView?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  newfuelActivity: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/newfuelActivityExport?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  fuelActivityReportView: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/fuelActivityReportView?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  geofenceSummaryReportsMapView: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/geoFenceSummaryMapView?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  routefenceReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/routefence?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },

  alarmReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/alarm?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  helmetStatusReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/helmetStatusReport?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  idleReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/idle?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  overSpeedingReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/vehicelOverSpeeding?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  NRReport: (groupId) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/vehicelsNotResponding/${groupId}?token=` + localStorage.refreshToken + `&companyId=${companyInfo.companyId}`
    );
  },
  vehiclesStatus: (groupId) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/vehiclesStatus/${groupId}?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  drivingScoreReport: ( comapnyId) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/drivingScore?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}` +
      `&company=` +
      comapnyId
    );
  },
  vehicleDrivingScoreReport: (comapnyId) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/vehicleDrivingScore?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}` +
      `&company=` +
      comapnyId
    );
  },
  drivingScoreGraph: (comapnyId) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReportV2}/reports/drivingScoreGraph?token=`+localStorage.refreshToken+`&companyId=${companyInfo.companyId}`+`&company=`+comapnyId
    );
  },
  vehicleTravelReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/vehicleTravelReport?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  vehicleTravelReportGraph: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/vehicleTravelReportGraph?token=` +localStorage.refreshToken +`&companyId=${companyInfo.companyId}`
    );
  },
  stoppageReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/stoppage?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  vehicleTrackingReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/vehicleTracking?token=` + localStorage.refreshToken + `&companyId=${companyInfo.companyId}`
    );
  },
  dliReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/dliReport?token=` + localStorage.refreshToken + `&companyId=${companyInfo.companyId}`
    );
  },
  vehicleTrackingTestReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/vehicleTrackingTest?token=` + localStorage.refreshToken + `&companyId=${companyInfo.companyId}`
    );
  },
  bycetrackingreport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/bycetrackingreport?token=` + localStorage.refreshToken + `&companyId=${companyInfo.companyId}`
    );
  },
  distanceTraveledReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urLReport}/reports/distance?token=` + localStorage.refreshToken + `&companyId=${companyInfo.companyId}`
    );
  },
  maintenanceReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/maintenance?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  otherMaintenanceReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/otherMaintenance?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  performanceReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/performance?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  vehiclesGeoFenceReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/vehiclesGeoFenceReport?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  vehiclesRouteFenceReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/vehiclesRouteFenceReport?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  rescueCellsite: (obj) => {
    obj = JSON.stringify(obj);
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/rescueCellSite?data=${obj}&token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  rosterLog: (obj) => {
    obj = JSON.stringify(obj);
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/rosterLog?data=${obj}&token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  gensetTemp: (tripId) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/trip/${tripId}/genset/temperature?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  geoFenceFuelReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (`${urLReport}/reports/geoFenceFuelReport?token=` + localStorage.refreshToken +`&companyId=${companyInfo.companyId}`);
  },
  pjpReport: (obj) => {
    obj = JSON.stringify(obj);
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/pjpReport?data=${obj}&token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  fetchLogs: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return `${urLReport}/reports/logs/${companyInfo.companyId}?token=${localStorage.refreshToken}`;
  },
  vehicleHistoryReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/vehicleHistoryReport?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
  geoFenceTourReport: () => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    return (
      `${urlV1}/reports/geofenceTour?token=` +
      localStorage.refreshToken +
      `&companyId=${companyInfo.companyId}`
    );
  },
};
const reportScheduleEndpoints = {
  vehicleList: (companyId) => {
    return (
      urlV1 +
      `/scheduleReport/vehicle?company=${companyId}&token=` +
      localStorage.refreshToken
    );
  },
  basePath: (company) => {
    const companyPart = company ? `&company=${company}` : "";
    return (
      urlV1 + `/scheduleReport?token=` + localStorage.refreshToken + companyPart
    );
  },
  reportWithId: (reportId) => {
    return (
      urlV1 + `/scheduleReport/${reportId}?token=${localStorage.refreshToken}`
    );
  },
};

const logMaintenance = {
  list: (vehicleId) => `${urlV1}/maintenance-log/vehicle/${vehicleId}`,
  newLog: () => `${urlV1}/maintenance-log`,
  newOtherLog: () => `${urlV1}/maintenance-log/other`,
  delete: (id) => `${urlV1}/maintenance-log/${id}`,
};

const PlayBackEndPoints = {
  fetchForPlateNumber: (jessionId, plateNumber, month, year, day, loc) =>
    `${MVDR_URL}/StandardApiAction_getVideoFileInfo.action?DevIDNO=${plateNumber}&CHN=-1&DownType=2&jsession=${jessionId}&LOC=${loc}&YEAR=${year}&MON=${month}&DAY=${day}&RECTYPE=-1&FILEATTR=2&BEG=0&END=86399&ARM1=0&ARM2=0&RES=0&STREAM=0&STORE=0`,
  login: (account, password) =>
    `${MVDR_URL}/StandardApiAction_login.action?account=${account}&password=${password}`,
  fetchAll: (jessionId) =>
    `${MVDR_URL}/StandardApiAction_getVideoFileInfo.action?DevIDNO=077834008670&CHN=-1&DownType=2&jsession=${jessionId}&LOC=1&YEAR=2021&MON=07&DAY=13&RECTYPE=-1&FILEATTR=2&BEG=0&END=86399&ARM1=0&ARM2=0&RES=0&STREAM=0&STORE=0`,
};

const partyFields = ['63d2891e720a9b5138d9cf2f'];
const engrofields = ['64ad5d565994fd70d6ed1523'];
const kElectricFields= ['63989f8eba00314a1b750a18'];
const warbleFields = ['66fd17422e6f872ff9a43886'];

export {
  domainEndPoints,
  permissionEndPoints,
  rolesEndPoints,
  authEndpoints,
  userEndpoints,
  thingsEndPoints,
  groupEndPoints,
  driverEndPoints,
  geoFenceEndPoints,
  reportsEndPoints,
  scheduleEndPoints,
  fuelEndPoint,
  reportScheduleEndpoints,
  logMaintenance,
  routeFenceEndPoints,
  PatrollingEndPoints,
  hotspotEndPoints,
  pjpEndPoints,
  companyEndPoints,
  PlayBackEndPoints,
  complaintsEndPoints,
  partyFields,
  engrofields,
  kElectricFields,
  warbleFields,
  ragAnalytics,
  telcoSiteEndpoints,
  trikesSummary
};
