import React from "react";
import Select from "react-select-nested-group";
import SelectComponent from "../select";
import { Popover, PopoverBody } from 'reactstrap';
import { DateRangePicker } from 'react-date-range';
import calenderIcon from '../../assets/img/calender.png';
import { Card, CardHeader } from "reactstrap";
import { subDays } from 'date-fns';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { map } from "lodash";
import trikes from "../../ducks/trikes";
import { Spinner } from '../common/Spinner/index';
import { PieChart, Pie, Cell } from 'recharts';
import TrikesCard from "./card";
import topFiveIcon from '../../assets/img/topMSM.png';
import moment from 'moment';
import toastr from "toastr";

import "./index.css"



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const TrikesSummary = (props) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState([])
  const [selectedSPD, setSelectedSPD] = React.useState([])
  const [searchTrikesAssets, setSearchVehicleValues] = React.useState([])
  const [showSD, setShowSD] = React.useState(false)
  const [disabledTrikes, setDisabledTrikes] = React.useState(false)
  const [disabledSPD, setDisabledSPD] = React.useState(false)
  const [dateRangePicker, setDateRange] = React.useState({
    selection: {
      startDate: (() => {
        const date = subDays(new Date(), 1);
        date.setHours(0, 0, 0, 0);
        return date;
      })(),
      endDate: (() => {
        const date = new Date();
        date.setHours(23, 59, 59, 999);
        return date;
      })(),
      key: 'selection',
    },
  });
  


  console.log("dateRangePicker 000 ", dateRangePicker)


  const clearSelection = () => {
    setDisabledSPD(false);
    setDisabledTrikes(false)
    setSelectedSPD([])
    setSearchValue([])
  };

  const handleRangeChange = async (payload) => {
  
    const adjustedEndDate = new Date(payload.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);
    payload.selection.endDate = adjustedEndDate;
  
    const difference = moment(payload.selection.endDate).diff(
      moment(payload.selection.startDate),
      "minutes"
    );
  
    if (difference > 31 * 24 * 60) {
      return toastr.error(
        "The selected time range exceeds the allowed limit."
      );
    }


    console.log("...payload ---> ", payload)
  
    setDateRange({ ...payload });
  };
  

  const toggle = () => {
    setPopoverOpen(!popoverOpen)
  };
  const filterTrike = async (value) => {
    setSearchValue({ ...value });
    setDisabledSPD(true)
  };

  const fetchOptions = async () => {
    await props.trikesAction.getThings();
    await props.trikesAction.getSubGroups();
  }

  const selectSPD = (selectedSPD) => {
    setSelectedSPD(selectedSPD)
    setDisabledTrikes(true)
  }

  const fetchAnalytics = async () => {
    if (searchValue.value) {
      await props.trikesAction.getSummary(searchValue.value, dateRangePicker)
    }
    else if (selectedSPD.length > 0) {
      await props.trikesAction.getSummary(selectedSPD, dateRangePicker)
    }
  }



  const fetchReport = async () => {
    if (searchValue.value) {
      await props.trikesAction.getSummaryAndDownloadReport(searchValue.value, dateRangePicker)
    }
    else if (selectedSPD.length > 0) {
      await props.trikesAction.getSummaryAndDownloadReport(selectedSPD, dateRangePicker)
    }
  }

  React.useEffect(() => {
    if (searchValue?.value) {
      setSelectedSPD([])
    }
  }, [searchValue]);

  React.useEffect(() => {
    if (selectedSPD?.length > 0) {
      setSearchValue([])
    }
  }, [selectedSPD]);

  React.useEffect(() => {
    fetchOptions()
  }, []);

  React.useEffect(() => {
    setSearchVehicleValues(map(props?.things, (item) => ({
      value: item._id,
      label: item.plateNumber || item.imei.substr(-8),
      item: item,
    })))
  }, [props.things]);

  return (
    <div>
      <div className="row">

        <div className="col-md-3">
          <Select
            name="form-field-name"
            isSearchable={true}
            placeholder="Search Trike"
            onChange={filterTrike}
            value={searchValue}
            options={searchTrikesAssets}
            isDisabled={disabledTrikes}
          />

          {disabledSPD && (
            <span aria-hidden="true" onClick={clearSelection} style={{ cursor: 'pointer', position: 'absolute', right: '55px', top: '7px', fontSize: '16px', color: "#000" }}>&times;</span>
          )}
        </div>
        <div className="col-md-3">
          <SelectComponent
            field="form-field-name"
            placeholder="Select SPD"
            preSelected={selectedSPD}
            multi={true}
            optionKeys={{ label: "name", value: "_id" }}
            options={props.groupList}
            onChange={selectSPD}
            disabled={disabledSPD}
          />

          {disabledTrikes && (
            <span aria-hidden="true" onClick={clearSelection} style={{ cursor: 'pointer', position: 'absolute', right: '55px', top: '7px', fontSize: '16px', color: "#000" }}>&times;</span>
          )}

        </div>
        <div className="col-xs-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="float-right">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <a onClick={toggle} id="Popover1">
                  <img className="rag-picker" src={calenderIcon} alt="" />
                </a>
                <Popover placement="bottom" isOpen={popoverOpen} toggle={toggle} target="Popover1">
                  <PopoverBody>
                    <div>
                      <DateRangePicker
                        onChange={handleRangeChange}
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        className={'PreviewArea'}
                        months={1}
                        ranges={[dateRangePicker.selection]}
                        direction="horizontal"
                        maxDate={new Date()}
                      />
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
              <div className="col-md-4 col-sm-6">
                <button onClick={fetchAnalytics} className='btn rag-primary-btn rag-score-filter-btn mx-2' >Filter
                </button>
              </div>
              <div className="col-md-1 col-sm-6"></div>
              <div className="col-md-4 col-sm-6">
                <button onClick={fetchReport} className='btn rag-primary-btn rag-score-filter-btn mx-2'>Extract Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.isLoading === true && (
        <Spinner />
      )}
      {/* {searchValue?.length === 0 && selectedSPD?.length === 0 && (
        <div className="vehicle-plate-Number-model">
          <p className="vehicle-plate-number">No Trikes Selected</p>
          <p className="vehicle-model">N/A</p>
        </div>
      )} */}

      <div className="container-fluid">
        <div className="row justify-content-center mr-5 mt-5">
          <div className="col-md-9 h-100">
            <div className="row justify-content-center">
              {Array.isArray(props?.data) && props.data.length > 0 ? (
                props.data.flatMap((dataGroup, groupIndex) =>
                  dataGroup.filter(item => item.title != "Name of MSM").map((item, itemIndex) => (
                    <div className="col-md-4" key={`${groupIndex}-${itemIndex}`}>
                      <TrikesCard title={item.title} number={item.value} icon={item.icon} />
                    </div>
                  ))
                )
              ) : (
                <p>No data available</p>
              )}
            </div>
          </div>

          <div className="col-md-3 mt-3">
            {Array.isArray(props?.data) && props.data.length > 0 && (
              props.data.flatMap((dataGroup, groupIndex) =>
                dataGroup
                  .filter(item => item.title === "Name of MSM")
                  .map((item, itemIndex) => (
                    <div
                      className={`col-md-4 ${item.title === "Name of MSM" ? "h-95" : ""}`}
                      key={`${groupIndex}-${itemIndex}`}
                    >
                      <TrikesCard title={"Name of MSM"} number={item.value} icon={topFiveIcon} />
                    </div>
                  ))
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    things: state.trikes.things,
    groupList: state.trikes.groupList,
    isLoading: state.trikes.isLoading,
    data: state.trikes.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  trikesAction: bindActionCreators(trikes.creators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TrikesSummary)