import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import * as _ from 'lodash';
import Modal from 'react-bootstrap4-modal';
import '../index.css';
import { Spinner, WhiteSpinner } from '../../common/Spinner/index';
import RouteFence from '../../../ducks/RouteFence';
import editIcon from '../../../assets/img/ic_edit.png';
import trash_bin from '../../../assets/img/trash_bin.png';

class RouteFenceListing extends Component {

  constructor() {
    super();
    this.state = {
      isLoadingRecords: true,
      search: '',
      RouteFences: [],
      RouteFenceToBeDeleted: {},
      deletingRouteFence: false,
      hoveredIndex: null,
    };
    this.onChangeData = this.onChangeData.bind(this);
    this.checkBoxAlertChanged = this.checkBoxAlertChanged.bind(this);
  }

  async componentDidMount() {

    await this
      .props
      .actions
      .getGroupRouteFences();

    this.setState({
      ...this.state,
      RouteFences: this.props.RouteFences,
      isLoadingRecords: false
    })
  }

  onChange(event) {
    const value = event.target.value;
    if (value) { this.setState({ ...this.state, search: value || '' }); } else {
      this.setState({
        ...this.state, search: value || '', RouteFences:
          this.props.RouteFences
      });
    }
  }
  navigateToCreateRouteFence() {
    this
      .props
      .history
      .push('/routefence/create');
  }

  updateRouteFence(item) {
    this
      .props
      .history
      .push(`/routeFence/update/${item._id}`);
  }
  showDeleteRouteFenceConfirmation(item) {
    this.setState({
      ...this.state,
      routeFenceToBeDeleted: item,
      showDeleteConfirmation: true
    })
  };

  async deleteRouteFence() {
    if (!this.state.deletingRouteFence) {
      await this.setState({
        deletingRouteFence: true,
      });
      await this
        .props
        .actions
        .deleteRouteFence({ id: this.state.routeFenceToBeDeleted });

      toastr.success("RouteFence deleted successfully!");

      let RouteFences = _.filter(this.state.RouteFences, item => {
        return item._id !== this.state.routeFenceToBeDeleted._id;
      })
      this.setState({
        ...this.state,
        showDeleteConfirmation: false,
        RouteFences,
        deletingRouteFence: false,
      })
    }
  }

  cancelDelete() {
    this.setState({ ...this.state, showDeleteConfirmation: false })
  }

  handleDarkSideForce() {
    toastr.error('Invalid file format, csv file is required!');
  }

  handleMouseEnter = (index) => {
    this.setState({ hoveredIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredIndex: null });
  };

  onChangeData(e) {

    if (e && e.target) {
      const index = e.target.id;

      let RouteFencesImport = this.state.RouteFencesImport;
      let item = RouteFencesImport[index]
      item[e.target.name] = e.target.value;
      RouteFencesImport[index] = item;
      this.setState({ ...this.state, RouteFencesImport })
    }
  };
  checkBoxAlertChanged(index, type) {

    let RouteFencesImport = this.state.RouteFencesImport;
    let item = RouteFencesImport[index]
    item[type] = !item[type];
    RouteFencesImport[index] = item;
    this.setState({ ...this.state, RouteFencesImport })
  }



  render() {
    let notFound;
    let token = localStorage.getItem('refreshToken');
    const RouteFences = this.state.RouteFences;
    if (!this.state.isLoadingRecords && RouteFences.length === 0) {
      notFound = (
        <div className='no-data-found' key='1'>
          <h2>No RouteFence added</h2>
        </div>)
    };

    const loader = <Spinner key='1' />
    const smallLoader = <WhiteSpinner key="1" />
    const { hoveredIndex } = this.state;

    return (

      <div>
        {this.state.showDeleteConfirmation ?
          <Modal
            visible={true}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName='delete-modal-dialog'>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Delete RouteFence?</h5>
            </div>
            <div className='modal-body'>
              <h6>Are you sure you want to delete RouteFence {this.state.RouteFenceToBeDeleted.name}?</h6>


            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .deleteRouteFence
                  .bind(this)}>
                {this.state.deletingRouteFence ? [smallLoader] : 'Confirm Delete'}
              </button>

              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                Cancel
              </button>
            </div>
          </Modal>
          : ''}

        <div className='row'>

          <div className='col-lg-12 col-md-12 col-sm-12'>
            {this.props.permissions.createRouteFence &&
              <span>
                <button
                  className='btn auxo-primary-btn margin-bottom-20'
                  onClick={this
                    .navigateToCreateRouteFence
                    .bind(this)}>Create Routefence</button>

                <span>

                </span>

              </span>
            }
          </div>
        </div>
        {this.state.isLoadingRecords ?
          [loader] : ''}
        <div className='row'>

          {!this.state.isLoadingRecords && RouteFences.length !== 0
            ? <div className='trip-table-heading'>
              <p className='width-45'>RouteFence</p>
              <p className='width-45'>APPLIED ON</p>
              <p className='width-10'>ACTIONS</p>
            </div>
            : [notFound]}

          {RouteFences.map((item, index) => {
            index = 'abc' + index + 'z';
            item = _.clone(item);


            let altersOn = 'Entry and Exit';
            if (item.entryEnabled && !item.exitEnabled) {
              altersOn = 'Entry Only';
            } else if (!item.entryEnabled && item.exitEnabled) {
              altersOn = 'Exit Only';
            } else if (!item.entryEnabled && !item.exitEnabled) {
              altersOn = 'None';
            }
            return (

              <div className='width-100' key={index}>
                <div className='trip-table-data'>

                  <p className='width-45'>

                    {/* <a id={`${index}`} className='trip-date width-10' onClick={this.openRouteFence.bind(this, item._id)}>
                                            <img className={item.isSelected ? 'list-view-transform width18' : 'width18'}  alt='' src={forwardIcon} />
                                        </a> */}
                    {item.name}
                  </p>
                  <span
                    key={index}
                    className="vehicle-container"
                    onMouseEnter={() => this.handleMouseEnter(index)}
                    onMouseLeave={this.handleMouseLeave}
                    style={{ position: 'relative'}}
                  >
                    <p className="width-45">{item.things.length} Vehicles</p>
                    {hoveredIndex === index && (
                      <div className="popup">
                        <ul>
                          {item.vehiclesName.map((thing, idx) => (
                            <li key={idx}>{thing}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </span>
                  <span>
                    {this.props.permissions.updateRouteFence &&
                      <a className='padding-10' onClick={this
                        .updateRouteFence
                        .bind(this, item)}><img src={editIcon} alt='' /></a>
                    }
                    {this.props.permissions.deleteRouteFence &&
                      <a onClick={this
                        .showDeleteRouteFenceConfirmation
                        .bind(this, item._id)}><img src={trash_bin} className='width16' alt='' /></a>
                    }
                  </span>



                </div>
              </div>

            )
          })}

        </div>
      </div>
    );
  }
}

RouteFenceListing.propTypes = {};
RouteFenceListing.defaultProps = {};
const mapStateToProps = state => ({ RouteFences: state.routeFence.routeFences, permissions: state.permissions });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(RouteFence.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(RouteFenceListing);
