import React, { useState, useEffect } from "react";
import Select from "react-select-nested-group";
import * as R from "ramda";
import moment from "moment";
import toastr from "toastr";
import transform from "./observationHelper";
import { Spinner } from "../common/Spinner";
import DateTime from "react-datetime";
import fetchLocation from "../../assets/img/Fetch location icon.png";
import Things from "../../ducks/Things";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import PaginatedTable from "../common/PaginatedTable";
Geocode.setApiKey("AIzaSyCZI1nlQrNvcDVSrAcnnSxJlou3uejXyQY");

const ObservationPage = (props) => {
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [things, setThings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableValues, setTableValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedHours, setSelectedHours] = useState({ value: 12, label: 12 });
  
  const columns = [
    { name: "Device Time", selector: "gpsTime", sortable: true, wrap: true, minWidth: "200px" },
    { name: "Plate", selector: "plate", wrap: true },
    { name: "location", selector: "latlng", wrap: true },
    { name: "Address", selector: "address" },
    { name: "Ignition", selector: "ignition" },
    { name: "Movement", selector: "movement" },
    { name: "GSM Signal", selector: "gsmSignal" },
    { name: "Sat", selector: "visibleSatellites" },
    { name: "External_Voltage (V)", selector: "externalVoltage" },
    { name: "Speed (KM/H)", selector: "speed" },
    { name: "Trip", selector: "trip" },
    { name: "Idling", selector: "idling" },
    { name: "Behaviour Type", selector: "greenDrivingType" },
    { name: "Behaviour Value", selector: "greenDrivingValue" },
    { name: "Battery_Voltage (V)", selector: "batteryVoltage" },
    { name: "Unplug", selector: "unplug" },
    { name: "BarCode Value", selector: "barcodeValue" },
    { name: "Analog Input", selector: "analogInput1" },
    { name: "Digital Input", selector: "digitalInput1" },
    { name: "Jamming", selector: "jamming" },
    { name: "Towing", selector: "towing" },
    { name: "Data_Mode", selector: "Data_Mode" },
    { name: "Sleep_Mode", selector: "Sleep_Mode" },
    { name: "GNSS_Status", selector: "GNSS_Status" },
    { name: "Fetch Location", cell: (data, dataIndex) => <a
    style={{ color: "#03ABCE" }}
    onClick={async (event) => {
      event.preventDefault();
      setIsLoading(true);
      const [lat, lng] = data.latlng.split(",");

      const response = await Geocode.fromLatLng(lat, lng);
      console.log("geocode response inside Observation ")
      const address = response.results[0].formatted_address;
      data.address = address;
      const _tableValues = tableValues;
      _tableValues[dataIndex] = data;
      setTableValues(_tableValues);
      setIsLoading(false);
    }}
  >
    <img src={fetchLocation} style={{ width: "32px" }} alt="" />
  </a> },
  ];
  const headers = [
    { label: "Device Time", key: "gpsTime" },
    { label: "Plate", key: "plate" },
    { label: "location", key: "latlng" },
    { label: "Address", key: "address" },
    { label: "Ignition", key: "ignition" },
    { label: "Movement", key: "movement" },
    { label: "GSM Signal", key: "gsmSignal" },
    { label: "Sat", key: "visibleSatellites" },
    { label: "External_Voltage (V)", key: "externalVoltage" },
    { label: "Speed (KM/H)", key: "speed" },
    { label: "Trip", key: "trip" },
    { label: "Idling", key: "idling" },
    { label: "Behaviour Type", key: "greenDrivingType" },
    { label: "Behaviour Value", key: "greenDrivingValue" },
    { label: "Battery_Voltage (V)", key: "batteryVoltage" },
    { label: "Unplug", key: "unplug" },
    { label: "BarCode Value", key: "barcodeValue" },
    { label: "Analog Input", key: "analogInput1" },
    { label: "Digital Input", key: "digitalInput1" },
    { label: "Jamming", key: "jamming" },
    { label: "Towing", key: "towing" },
    { label: "Data_Mode", key: "Data_Mode" },
    { label: "Sleep_Mode", key: "Sleep_Mode" },
    { label: "GNSS_Status", key: "GNSS_Status" },


  ];

  const hoursArray = R.unfold((h) => (h <= 24 ? [h, h + 1] : false), 1);

  const actions = {
    async onSubmit(event) {
      event.preventDefault();
      if (!selectedVehicle) {
        return toastr.error("Vehicle is required.");
      }
      if (!selectedDate) {
        return toastr.error("Start date is required.");
      }
      if (!selectedHours) {
        return toastr.error("Hours selection is required.");
      }
      const endDate = moment(selectedDate).add(selectedHours.value, "hours");

      const interval = {
        startDate: moment(selectedDate),
        endDate: moment(endDate),
      };
      setIsLoading(true);
      const observations = await props.actions.getObservations(
        selectedVehicle.value,
        JSON.stringify({ interval })
      );
      setIsLoading(false);
      const filter = (row) => row.gpsTime !== "N/A";
      const values = R.compose(
        R.filter(filter),
        (s) => {
          return s;
        },
        R.map(transform(selectedVehicle.label))
      )(observations);
      setTableValues(values);
    },

    handleVehicleChange(option) {
      setSelectedVehicle(option);
    },
  };

  const loadThings = async () => {
    setIsLoading(true);
    const things = await props.actions.fetchThings();
    const mappedValues = R.map((thing) => ({
      value: thing.name,
      label: `${thing.plateNumber || "N/A"}`,
    }))(things);
    setThings(mappedValues);
    setIsLoading(false);
  };

  useEffect(() => {
    loadThings();
  }, []);
  return !isLoading ? (
    <div>
      <div className="row" style={{ margin: "0px 0px 25px 0" }}>
        <div className="col-lg-3 col-sm-3">
          <label>Select Vehicle</label>
          <Select
            name="form-field-selectedThing"
            value={selectedVehicle}
            searchable={true}
            onChange={actions.handleVehicleChange}
            options={things}
          />
        </div>
        <div className="col-lg-3 col-sm-3">
          <label>Date</label>
          <DateTime
            value={selectedDate}
            dateFormat="DD-MM-YYYY"
            timeFormat="HH:mm"
            onChange={setSelectedDate}
          />
        </div>
        <div className="col-lg-3 col-sm-3">
          <label>Duration (Hours)</label>
          <Select
            name="form-field-name"
            isClearable={false}
            isSearchable={true}
            placeholder="Select Vehicle"
            defaultValue={selectedHours}
            onChange={setSelectedHours}
            options={hoursArray.map((h) => {
              return { value: h, label: h };
            })}
          />
        </div>
        <div className="col-lg-3" style={{ padding: "25px 0 0 25px" }}>
          <button
            className={`btn btn-primary search-btn search-button`}
            onClick={actions.onSubmit}
          >
            Search
          </button>
        </div>
      </div>
      <PaginatedTable
        title="Observation List"
        columns={columns}
        data={tableValues}
        headers={headers}
        filename="Observation List"
      />
    </div>
  ) : (
    <div
      style={{
        marginTop: "20%",
      }}
    >
      <Spinner />
    </div>
  );
};

const mapStateToProps = (state) => ({ things: state.things.things });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Things.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ObservationPage);
