import React from 'react';
import operationalTrikeIcon from "../../assets/img/operational.png";
import staticTrikesIcon from "../../assets/img/static.png";
import './index.css';

const TrikesCard = ({ title, number, icon }) => {
  const isStatusCard = title === "Status";
  const isMSM = title === "Name of MSM";


  return (
    <div className={`card ${isMSM && 'h-100 mt-0'}`}>
      {isStatusCard ? (
        <>
          <div className="card-header d-flex justify-content-between align-items-center pt-5">
            <span className='card-header-title'>Operational</span>
            <span className='card-header-title'>Static</span>
          </div>
          <div className="card-icons d-flex justify-content-between align-items-center w-100 mt-5">
            <img src={operationalTrikeIcon} alt="Operational Icon" className="card-img" />
            <img src={staticTrikesIcon} alt="Static Icon" className="card-img" />
          </div>
          <div className="card-body d-flex justify-content-between">
            <p>{number?.operationalTrike || 0}</p>
            <p>{number?.staticTrike || 0}</p>
          </div>
        </>
      ) : (
        <>
          <div className="card-header"><span className='card-header-title'>{title}</span></div>
          <div className="card-body">
            <img src={icon} alt={title} className="card-img" />
            {isMSM ? (
              Array.isArray(number) ? (
                <ol>
                  {number.map((single, index) => (
                    <li key={index}>{single.thingName}</li>
                  ))}
                </ol>
              ) : (
                <p>{number.thingName || "N/A"}</p>
              )
            ) : (
              <p>{number}</p>
            )}

          </div>
        </>
      )}
    </div>
  );
};

export default TrikesCard;
